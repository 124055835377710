<template>
  <div class="w-full h-full group/cell items-center">
    <LabelListPopover
      ref="labelRef"
      source="board"
      heading="Add labels"
      :selected-ids="map(filterdLabels, 'id')"
      :parent-id="task.boardId"
      :disabled="disabled"
      :task-id="task.id"
      class="w-full h-full flex items-center"
      :ui="{
        width: 'w-[280px]',
        trigger: `flex ${disabled ? '!cursor-not-allowed' : ''}`,
      }"
      @select="onToggleLabels"
      @remove="onToggleLabels"
      @update:open="onUpdateOpen"
    >
      <div :class="['w-full h-full', { active: open }, cellClass]">
        <ResizeLabels
          :labels="filterdLabels || []"
          :width="width"
          :get-label-style="getColorPreset"
          @click="emit('click')"
        />
      </div>
    </LabelListPopover>
  </div>
</template>

<script lang="ts" setup>
import { map } from 'lodash-es'
import type { TaskItem } from '#task/types'
import type { Label } from '#label/types'
import type { FieldCallback } from '#field/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  width: {
    type: Number,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  cellClass: {
    type: String,
    default: '',
  },
})
const emit = defineEmits<{
  (e: 'set-callback', callback: FieldCallback): void
  (e: 'click'): void
  (e: 'update:open', value: boolean): void
}>()

const { toggleLabels } = useUpdateTask()
const { tasks } = useBoardTasksLoader()
const taskIds = computed(() =>
  selectedTaskIds.value.has(props.task.id)
    ? Array.from(selectedTaskIds.value)
    : [props.task.id]
)

const onToggleLabels = (label: Label) => {
  const taskIds = selectedTaskIds.value.has(props.task.id)
    ? Array.from(selectedTaskIds.value)
    : [props.task.id]
  return toggleLabels({
    taskIds,
    detachLabelIds: [],
    labelIds: [label.id],
  })
}

const { selectedTaskIds } = useListViewSharedState()

const labelRef = ref()
const open = ref(false)
const filterdLabels = computed(() =>
  props.task.labels?.filter((label) => label.id)
)

const onUpdateOpen = (value: boolean) => {
  open.value = value
  emit('update:open', value)
}

onMounted(() => {
  emit('set-callback', {
    open: () => labelRef.value?.open(),
    delete: () => {
      taskIds.value.forEach((taskId) => {
        const task = tasks.value.find((task) => task.id === taskId)
        if (task && task.labelIds.length) {
          toggleLabels({
            taskIds: [taskId],
            detachLabelIds: [],
            labelIds: task.labelIds,
          })
        }
      })
    },
  })
})
</script>
